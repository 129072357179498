import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import Pagination from "./pagination";

export default function MidText({ totalPages, currentPage, recentPagesData }) {
  return (
    <div className="bg-white">
      <div className="midContainer flex flex-col justify-center mx-auto w-full md:px-0 px-4 md:w-9/12 py-7 max-w-6xl">
        {recentPagesData?.map(({ node }) => {
          const { data } = node;
          const {
            seo_h1,
            seo_project_create_date,
            seo_project_small_title,
            slug,
          } = data;

          return (
            <div className="content border-b font-primary border-black pt-8 pb-4 flex flex-col ">
              <Link
                to={slug}
                className="text-midBlue text-sm font-semibold pt-2 max-w-max"
              >
                <span className="py-4 text-24 font-secondary">{seo_h1}</span>
              </Link>
              <p className="text-sm font-light text-17 py-4 font-secondary">
                {moment(seo_project_create_date).format("MMMM DD, YYYY")}
              </p>
              <p className="py-4 font-opensans text-new">
                {seo_project_small_title}
              </p>
              <Link
                to={slug}
                className="text-orange hover:text-newOrange-100 transition-colors text-sm text-17 font-opensans font-semibold pt-2 max-w-max"
              >
                Read more
              </Link>
            </div>
          );
        })}

        <Pagination totalPages={totalPages} currentPage={currentPage} baseUrl="/recent-translations.php" />
      </div>
    </div>
  );
}
