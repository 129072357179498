import React from "react";
import { Link } from "gatsby";

function Pagination({ totalPages, currentPage, baseUrl }) {
  const blueColor = "#F07A39";

  const paginatedValues = () => {
    if (currentPage < totalPages - 4) {
      return [
        {
          copy: currentPage,
          selected: true,
        },
        {
          link: baseUrl + "/p/" + (currentPage + 1),
          copy: currentPage + 1,
          selected: false,
        },
        {
          link: baseUrl + "/p/" + (currentPage + 2),
          copy: currentPage + 2,
          selected: false,
        },
        { copy: "..", selected: false },
        {
          link: baseUrl + "/p/" + totalPages,
          copy: totalPages,
          selected: false,
        },
      ];
    } else {
      return [
        {
          link: baseUrl + "/p/" + (totalPages - 4),
          copy: totalPages - 4,
          selected: currentPage === totalPages - 4,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 3),
          copy: totalPages - 3,
          selected: currentPage === totalPages - 3,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 2),
          copy: totalPages - 2,
          selected: currentPage === totalPages - 2,
        },
        {
          link: baseUrl + "/p/" + (totalPages - 1),
          copy: totalPages - 1,
          selected: currentPage === totalPages - 1,
        },
        {
          link: baseUrl + "/p/" + totalPages,
          copy: totalPages,
          selected: currentPage === totalPages,
        },
      ];
    }
  };

  return (
    <>
      <div className="bottom flex flex-col sm:flex-row pt-6 justify-between items-center">
        {currentPage > 1 ? (
          <Link
            to={baseUrl + "/p/" + (currentPage - 1)}
            className="btn border w-full sm:w-1/3 bg-darkBlue max-w-max px-4 py-4 mb-4 text-white text-base font-medium"
          >
            {`<< Previous`}{" "}
          </Link>
        ) : (
          <button className="btn border w-full sm:w-1/3 bg-darkBlue max-w-max px-7 py-4 mb-4 text-base font-medium text-white">
            {`<< Previous`}{" "}
          </button>
        )}

        <div className="pagination flex flex-row w-full py-6 sm:w-1/3 justify-between text-xl">
          {paginatedValues()?.map((p, i) => {
            return (
              <Link
                className="mx-2"
                to={p?.link}
                key={i}
                style={p.selected ? { color: blueColor } : { color: "" }}
              >
                {p?.copy}
              </Link>
            );
          })}
        </div>

        {currentPage < totalPages - 1 ? (
          <Link
            to={baseUrl + "/p/" + (currentPage + 1)}
            className="btn border w-full sm:w-1/3 bg-orange max-w-max px-7 py-4 mb-4 text-base font-medium text-white"
          >
            {`Next >>`}{" "}
          </Link>
        ) : (
          <button className="btn border w-full sm:w-1/3 bg-orange max-w-max px-7 py-4 mb-4 text-base font-medium text-white">
            {`Next >>`}{" "}
          </button>
        )}
      </div>
    </>
  );
}
export default Pagination;
